.wrapper {
  padding: clamp(34px, calc(36vw/7.68), 39px) 0 clamp(68px, calc(72vw/7.68), 78px);
  background-color: rgba(29,30,29,.8);
  backdrop-filter: blur(3px);
  > div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 24px 32px;
    @media (max-width: 699px){
      justify-content: center;
      p {
        justify-content: center;
        width: 100%;
        order: 2;
      }
    }
  }
  p {
    color: var(--secondary-600);
    display: inline-flex;
    gap: 8px;
    align-items: center;
    a {
      transition: opacity .5s;
      &:hover {
        opacity: .62;
      }
    }
  }
}